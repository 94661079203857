import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../../layouts/Default';
import Section from '../../components/Section';
import useContentLanguageSwitcher from '../../hooks/useContentLanguageSwitcher';
import content from '../../../content/faq/index';

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '48px',
    lineHeight: '65px',
    marginTop: '4rem',
  },
  fontWeight: 'bold',
  fontSize: '32px',
  lineHeight: '40px',
  marginTop: '1rem',
  marginBottom: '1.5rem',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
}));

const Heading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
  fontSize: '16px',
  fontWeight: 600,
  color: '#212353',
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const FAQPageTemplate = ({
  helmet,
  faq,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Section>
        {helmet || ''}
        <Title
          variant="h1"
          component="h1"
        >
          {faq.title || 'title' }
        </Title>
        <Divider />
        <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="tabs">
          {faq.sections && faq.sections.map(({ tab }, i) => (
            <Tab key={`tab_${tab}_${i}`} label={tab} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Section>
      { faq.sections && faq.sections.map(({ subhead }, i) => (
        <TabPanel key={`sectionsx__${i}`} value={value} index={i}>
          {subhead.map(({ title, questions }, i2) => (
            <Section key={`tabpanel_${title}_${i2}`}>
              <Subtitle
                variant="h4"
                component="h4"
              >
                {title}
              </Subtitle>
              {questions.map(({ question, answer }, i3) => (
                <Accordion key={`questions_${i3}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={question}
                    id={question}
                  >
                    <Heading>{question}</Heading>
                  </AccordionSummary>
                  <AccordionDetails
                    className="content"
                    sx={(theme) => ({
                      '& .faq-content': {
                        height: '100%',
                        width: '100%',
                        marginLeft: theme.spacing(),
                      },
                    })}
                  >
                    <ReactMarkdown
                      className="faq-content"
                    >
                      {answer}
                    </ReactMarkdown>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Section>
          ))}
        </TabPanel>
      ))}
    </>
  );
};

FAQPageTemplate.propTypes = {
  helmet: PropTypes.object,
  faq: PropTypes.object,
};

export default function FAQPage(props) {
  const [faq, setFaq] = useState({});
  const [data] = useContentLanguageSwitcher({ content });

  useEffect(() => {
    setFaq(data);
  }, [data]);

  return (
    <Layout>
      <FAQPageTemplate
        helmet={
          <Helmet title={`FAQ | ${props.data.site.siteMetadata.title}`} />
        }
        faq={faq}
      />
    </Layout>
  );
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export const faqPageQuery = graphql`
  query FAQQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
