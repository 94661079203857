/* eslint-disable camelcase */
import de from './faq.de.yaml';
import en from './faq.en.yaml';
import en_US from './faq.en_US.yaml';
import es from './faq.es.yaml';
import ga from './faq.ga.yaml';
import it from './faq.it.yaml';
import pt from './faq.pt.yaml';
import fr from './faq.fr.yaml';

export default {
  de,
  en,
  en_US,
  es,
  ga,
  it,
  pt,
  fr,
};
